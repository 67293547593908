<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<AccountNav></AccountNav>
			</div>
			<div class="admin-col whole_000 whole_fff">
				<div class="acc_box api_box accu_box">
					<div class="container">
						<div class="acc-hd flex flex-ac flex-zBetween">
							<div class="acc-tit">{{$t('mAccount')}}</div>
						</div>
						<div class="acc-in flex flex-ac flex-zEnd">
							<div class="acc-in-r acc-btn flex flex-ac">
								<el-button type="primary" class="btn btn-mb0" @click="dialogVisible = true">
									{{$t('transfer')}}
								</el-button>
								<el-input v-model.trim="searchInput" @input="search" :placeholder="$t('placeholder')"
									prefix-icon="el-icon-search"></el-input>
							</div>
						</div>
						<!-- 资金划转 -->
						<el-dialog :visible.sync="dialogVisible" width="490px" custom-class="custom-dialog">
							<div slot="title" class="dialog-title"><b>{{$t('transfer')}}-USDT</b></div>
							<div class="dialog-content">
								<div class="transfer-form">
									<div class="transfer-form-item">
										<img src="../../assets/photo/web/form.png" alt="">
										<div class="transfer-form-item__label" v-if="transferDirection == 1">
											{{$t('czth2')}}
										</div>
										<div class="transfer-form-item__label" v-else>{{$t('czth3')}}</div>
										<div class="flex_bd">
											<el-select v-model="walletOne" :placeholder="$t('meun10_1')"
												style="width: 100%;">
												<el-option v-for="(item,index) in option" :label="item.label"
													:value="item.value" :key="index">
												</el-option>
											</el-select>
										</div>
									</div>
									<div class="transfer-form-icon">
										<div class="left-icon" v-if="transferDirection == 1" @click="changeTo(2)">
											<el-tooltip class="item" effect="dark" :content="$t('clickchange')"
												placement="bottom">
												<el-button><i class="el-icon-bottom"></i></el-button>
											</el-tooltip>
										</div>
										<div class="left-icon" v-if="transferDirection == 2" @click="changeTo(1)">
											<el-tooltip class="item" effect="dark" :content="$t('clickchange')"
												placement="bottom">
												<el-button><i class="el-icon-top"></i></el-button>
											</el-tooltip>
										</div>
										<!-- <div class="right-icon"><i class="el-icon-sort"></i></div> -->
									</div>
									<div class="transfer-form-item">
										<img src="../../assets/photo/web/to.png" alt="">
										<div class="transfer-form-item__label" v-if="transferDirection == 1">
											{{$t('czth3')}}
										</div>
										<div class="transfer-form-item__label" v-else>{{$t('czth2')}}</div>
										<div class="flex_bd">
											<el-select v-model="walletTwo" :placeholder="$t('uplaceholder')"
												@change="select2" style="width: 100%;">
												<el-option v-for="item in tradeCoins" :label="item" :value="item"
													:key="item">
													{{ item }} {{$t('mAccount')}}
												</el-option>
											</el-select>
										</div>
									</div>
								</div>
								<div class="quota-cell">
									<div class="quota-cell-item">{{$t('udTxt5')}}：{{assetsWallet.balance.toFixed(2)}}
									</div>
									<div class="quota-cell-item">{{$t('udTxt5')}}：{{swapWallet.balance.toFixed(2)}}
									</div>
								</div>
								<div class="transfer-cell">
									<div class="transfer-cell__hd">{{$t('udTxt6')}}</div>
									<div class="transfer-cell__bd">
										<div class="transfer-input flex_bd">
											<el-input type="number" v-model="amount" :min="0"></el-input>
											<!-- <el-input-number v-model="amount" :placeholder="$t('udTxt6')" :min="0"
												:controls="false"></el-input-number> -->
										</div>
										<el-button type="text" @click="tranfer">{{$t('udTxt7')}}</el-button>
									</div>
								</div>
							</div>
							<div slot="footer" class="dialog-footer">
								<el-row :gutter="14">
									<el-col :span="12">
										<el-button @click="closeDia">{{$t('close')}}</el-button>
									</el-col>
									<el-col :span="12">
										<el-button type="primary" @click="save">{{$t('hztxt')}}
										</el-button>
									</el-col>
								</el-row>
							</div>
						</el-dialog>
						<div class="acc-table exte-table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="coinId" :label="$t('bith7')">
									<template slot-scope="scope">
										<div>
											{{scope.row.coinId}} {{$t('mAccount')}}
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="balance" :label="$t('bith4')">
									<template slot-scope="scope">
										<div>
											<span v-if="scope.row.balance == 0">
												{{scope.row.balance}}
											</span>
											<span v-else>{{ scope.row.balance.toFixed(4) }}</span>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="frozenBalance" :label="$t('bith6')">
									<template slot-scope="scope">
										<div>
											<span v-if="scope.row.frozenBalance == 0">
												{{scope.row.frozenBalance}}
											</span>
											<span v-else>{{ scope.row.frozenBalance.toFixed(4) }}</span>
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		walletList,
		secondTrans,
		orderCoins
	} from '@/api/api/second'
	import {
		assetWallet
	} from '@/api/api/user'
	import AccountNav from '@/components/AccountNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			AccountNav,
			Foot
		},
		data() {
			return {
				transferDirection: 1,
				searchInput: '',
				tableData: [],
				all: [],
				dialogVisible: false,
				walletOne: '1',
				walletTwo: '',
				assetsWallet: {
					balance: 0
				},
				swapWallet: {
					balance: 0
				},
				amount: 0,
				symbol: 'USDT',
				tradeCoins: [],
				option: [{
					value: '1',
					label: this.$t('meun10_1')
				}]
			};
		},
		mounted() {
			this.getList()
			this.getWallet()
			this.getTradeCoins()
		},
		methods: {
			getList() {
				walletList().then(res => {
					if (res.code == 0) {
						this.tableData = res.data
						this.all = res.data
					} else {
						this.$message.error(this.$t('loginFirst'));
					}
				})
			},
			getTradeCoins() {
				orderCoins().then(res => {
					if (res.code == 0) {
						this.tradeCoins = res.data;
					} else {
						this.$notify.error({
							title: this.$t("apiTxt2"),
							message: res.message
						});
					}
				})
			},
			changeTo(val) {
				this.transferDirection = val;
				this.amount = 0;
			},
			search(value) {
				let keyWord = value
				this.tableData = this.all.filter(item => {
					return item.coinId.indexOf(keyWord) != -1 && item.coinId.localeCompare(keyWord) != -1
				})
			},
			getWallet() {
				assetWallet({
					symbol: this.symbol,
				}).then(res => {
					if (res.code == 0) {
						this.assetsWallet = res.data
					} else {
						this.$message.error(this.$t('loginFirst'));
					}
				})
			},
			select2(value) {
				let arr = this.all.filter(item => {
					return item.coin.unit == value
				})
				this.symbol = value
				this.walletTwo = value + ' ' + this.$t('udTxt8')
				this.swapWallet = arr[0]
				this.getWallet()
			},
      tranfer() {
        if(this.transferDirection==1){
          //现货
          this.amount = this.assetsWallet.balance
        }else {
          this.amount = this.swapWallet.balance
        }
      },
			closeDia() {
				this.amount = 0
				this.walletTwo = ''
				this.dialogVisible = false
			},
			save() {
				if (this.walletTwo == '') {
					this.$message(this.$t('swappleaseselectwallet'));
				} else if (this.amount == undefined) {
					this.$message(this.$t('swappleaseinputamount'));
				} else if (this.amount == 0) {
					this.$message(this.$t('swappleaseinputamount'));
				} else {
					let params = {
						unit: this.symbol,
						from: this.transferDirection == 1 ? 0 : 2,
						to: this.transferDirection == 1 ? 2 : 0,
						amount: this.amount
					};

					secondTrans(params).then(res => {
						if (res.code == 0) {
							this.getList()
							this.getWallet()
						} else {
							this.$message.error(res.message)
						}
					})
					this.dialogVisible = false
				}
			},
		}
	}
</script>
