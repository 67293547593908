import request from '../request'


// 根据币种分页查询广告
export function walletList(data) {
	return request({
		url: '/second/wallet/list',
		method: 'POST',
		data: data
	})
}


// 根据币种分页查询广告
export function secondTrans(data) {
	return request({
		url: '/second/wallet/trans',
		method: 'POST',
		data: data
	})
}


// 获取币种缩略行情
export function symbolThumb(data) {
	return request({
		url: '/second/symbol-thumb',
		method: 'POST',
		data: data
	})
}


// 获取某交易对详情
export function symbolInfo(data) {
	return request({
		url: '/second/symbol-info',
		method: 'POST',
		data: data
	})
}


// 查询最近成交记录
export function latestTrade(data) {
	return request({
		url: '/second/latest-trade',
		method: 'POST',
		data: data
	})
}


//  获取某交易对详情mini
export function plateMini(data) {
	return request({
		url: '/second/exchange-plate-mini',
		method: 'POST',
		data: data
	})
}


// 获取当前币种指定期数ID的参与记录
export function orderCurrent(data) {
	return request({
		url: '/second/order/current',
		method: 'POST',
		data: data
	})
}



// 获取当前币种历史参与记录
export function orderHistory(data) {
	return request({
		url: '/second/order/history',
		method: 'POST',
		data: data
	})
}


// 获取支持交易的币种
export function orderCoins(data) {
	return request({
		url: '/second/order/coins',
		method: 'POST',
		data: data
	})
}


// cycles
export function orderCycles(data) {
	return request({
		url: '/second/order/cycles',
		method: 'POST',
		data: data
	})
}


// 查询期权钱包余额
export function secondBalance(data) {
	return request({
		url: '/second/wallet/balance/' + data.symbol,
		method: 'POST',
		data: data
	})
}


// 添加订单
export function orderAdd(data) {
	return request({
		url: '/second/order/add',
		method: 'POST',
		data: data
	})
}
